import React, { useContext, useEffect, useState } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import InfoBanner from '../infoBanner'

import { LanguageContext } from "../../utils/LanguageContext"

const english = <>
  Currently <strong>we don't ship items to countries outside of Europe</strong> and we apologise for the inconvience. Please reach out to us by using the <AniLink to="/contact/" fade aria-label="Go to Contact page">Contact form</AniLink>.
  <br />
  <hr />
  <strong>Free delivery to Spain.</strong>
</>

const spanish = <>
  Actualmente <strong>no enviamos artículos a países fuera de Europa</strong>, disculpe por las molestias. Contacte con nosotros mediante <AniLink to="/contact/" fade aria-label="Go to Contact page">el formulario de contacto</AniLink>.
  <br />
  <hr />
  <strong>Envío gratis a España.</strong>
</>

const BasketDeliveryInfoBanner = () => {
  const { language } = useContext(LanguageContext)
  const [ userLanguage, setUserLanguage ] = useState(language)

  useEffect(() => {
    setUserLanguage(language)
  }, [language])

  // * Initially show the english description
  // * upon hydration check the user language and adjust accordingly
  return (
    <>
      <InfoBanner>
        {!userLanguage ? english : userLanguage === "Spanish" ? spanish : english}
      </InfoBanner>
    </>
  )
}

export default BasketDeliveryInfoBanner