import React, { useContext, useEffect, useState } from 'react'
// ! To best leverage Stripe’s advanced fraud functionality, ensure that Stripe.js is loaded on every page of your customer’s checkout journey, not just your checkout page
import { loadStripe } from "@stripe/stripe-js"

import Loader from '../loader';
import { BasketContext } from '../../utils/BasketContext'

const BasketAddressForm = () => {
  const [loading, setLoading] = useState(false)
  const [AddressForm, setAddressForm] = useState(null)
  const { basketItems, shipping } = useContext(BasketContext)

  useEffect(() => {
    if (!basketItems.length) {
      return
    }
    const redirectToCheckout = async () => {
      setLoading(true)
      const { error } = await stripeRedirectToCheckout(basketItems, shipping)

      if (error) {
        // console.warn("Error:", error)
        setLoading(false)
        alert("There was something wrong with your request :/ Please try again in a while.")
      }
    }

    const loadAddressForm = async () => {
      const { default: AddressFormComponent } = await import(/* webpackChunkName: "AddressForm" */ '../form/addressForm')
      setAddressForm(React.createElement(AddressFormComponent, {
        onSubmit: redirectToCheckout,
      }))
    }
    loadAddressForm()
  }, [basketItems, shipping])

  if (!AddressForm || !basketItems.length) {
    return <></>
  }

  return (
    <div>
      <h2>Your shipping address</h2>
      {loading && <Loader />}
      {AddressForm}
    </div>
  )
}


let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

/**
 *
 * @param {*} basketItems
 * @param {*} address - Example:
 */
const stripeRedirectToCheckout = async (basketItems, shipping) => {
  const stripe = await getStripe()

  const lineItems = basketItems.map(basketItem => ({
    price: basketItem._stripe_product_details.metadata._stripe_price_id,
    quantity: basketItem.quantity
  }))

  if (shipping && shipping.price > 0) {
    lineItems.push({
      price: shipping.priceId,
      quantity: 1
    })
  }

  const response = await stripe.redirectToCheckout({
    mode: "payment",
    lineItems,
    successUrl: process.env.GATSBY_STRIPE_SUCCESS_REDIRECT_URL,
    cancelUrl: process.env.GATSBY_STRIPE_CANCEL_REDIRECT_URL,
    submitType: "pay"
  })

  return response
}

export default BasketAddressForm
