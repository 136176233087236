import React, { useContext, useState } from 'react'
import Img from "gatsby-image"
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FaTrash } from 'react-icons/fa'


import QuantitySelector from '../shop/quantitySelector';

import * as BasketListItemStyles from "./basketListItem.module.css";
import * as ShopButtonStyles from '../shop/shopButton.module.css';

import { BasketContext } from '../../utils/BasketContext'

const BasketListItem = ({ item, noQuantitySelector }) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const [showQuantityConfirmButton, setQuantityConfirmButton] = useState(false)
  const { setBasketItem, removeBasketItem } = useContext(BasketContext)

  const handleQuantityChange = quantityChange => {
    const newQuantity = quantity + quantityChange

    // * although input won't go below 1 anyway - check here also
    if (newQuantity < 0) {
      return
    }

    setQuantity(newQuantity)

    if (!showQuantityConfirmButton) {
      setQuantityConfirmButton(true)
    } else if (item.quantity === newQuantity) {
      setQuantityConfirmButton(false)
    }
  }

  const confirmChangeInQuantity = event => {
    event.preventDefault()
    // ! because this new quanity will be added instead of replacing the old one
    // ! use the item already in basket and calculate the difference in quantity
    // ! that will be added later by BasketContext
    const difference = quantity - item.quantity

    setBasketItem({ ...item, quantity: difference })

    setTimeout(() => {
      setQuantityConfirmButton(false)
    }, 200)
  }

  return (
    <li className={BasketListItemStyles.listItem}>
      {/* thumbImages supplied from the shopItemPage component. Limit to 2 images */}
      {item.images.filter((_, i) => i < 2).map((image, i) => (
        <Img
          className={BasketListItemStyles.backgroundImage}
          key={item._stripe_product_details.id + i}
          fixed={image.fixed}
        />
      ))}

      <div className={BasketListItemStyles.itemDetails}>
        <AniLink
          to={item.shopLink}
          fade
          aria-label="Got to item's detail page"
        >
          <span className={BasketListItemStyles.title}>
            {item.title}{item.size ? ` - ${item.size.label}` : ""}
          </span>
        </AniLink>
        <p className={BasketListItemStyles.price}>
          {`€ ${item.price * item.quantity}`}
          <span className={BasketListItemStyles.priceDescription}>({item.price} * {item.quantity})</span>
        </p>
      </div>

      {!noQuantitySelector &&
        <div className={BasketListItemStyles.quantitySelectorWrapper}>
          <QuantitySelector quantity={quantity} onQuantityChange={handleQuantityChange} />
          {showQuantityConfirmButton &&
            <button
              className={[ShopButtonStyles.shopButton, BasketListItemStyles.quantityConfirmButton].join(" ")}
              title="Confirm change in quantity"
              onClick={confirmChangeInQuantity}
            >CONFIRM QUANTITY</button>
          }
        </div>
      }

      <button
        className={BasketListItemStyles.removeButton}
        title="Remove all items from the basket"
        onClick={removeBasketItem.bind(this, item)}
      >
        <FaTrash />
      </button>
    </li>
  )
}

export default BasketListItem
