import React from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

import * as SizeSelectorStyles from './sizeSelector.module.css';
import * as QuantitySelectorStyles from "./quantitySelector.module.css";

export const QUANTITY_WARNING_THRESHOLD = 5;
export const DEFAULT_QUANTITY = 1;

const QuantitySelector = ({ quantity, onQuantityChange }) => {
  return (
    <div className={[SizeSelectorStyles.selector , QuantitySelectorStyles.container].join(" ")}>
      <button
        onClick={onQuantityChange.bind(this, -1)}
        type="button"
        title="Decrease item quantity"
        className={QuantitySelectorStyles.selector}
        disabled={quantity === 1}
        aria-label="Decrease item quantity"
      ><FaMinus /></button>

      <input
        type="number"
        min="1"
        name="quantity"
        readOnly
        aria-label='Item quantity'
        value={quantity}
        className={QuantitySelectorStyles.quantityInput}
      />

      <button
        onClick={onQuantityChange.bind(this, 1)}
        type="button"
        title="Increase item quantity"
        className={QuantitySelectorStyles.selector}
        aria-label="Increase item quantity"
      ><FaPlus /></button>
    </div>
  )
}

export default QuantitySelector