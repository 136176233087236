import React, { useContext, useState, useEffect } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import BasketListItem from './basketListItem'

import { BasketContext } from '../../utils/BasketContext';

import * as BasketListStyles from "./basketList.module.css";

const BasketList = () => {
  const { basketItems, shipping } = useContext(BasketContext)
  const [ hasMounted, setHasMounted ] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  // TODO: Create a loader component and show this instead on the SSR'ed initial response
  // if (!hasMounted) {
  //   return <Loader />
  // }

  if (!hasMounted || !basketItems.length) {
    // * SSR
    return (
      <div className={BasketListStyles.emptyBasketWrapper}>
        <h2>Your shopping basket is empty</h2>
        <AniLink
          to="/shop/dresses/"
          fade
          className={BasketListStyles.continueShoppingLink}
          aria-label={`Continue shopping`}
        >
          Continue shopping
        </AniLink>
      </div>
    )
  }

  const listItems = basketItems.map(basketItem => <BasketListItem key={basketItem._stripe_product_details.id} item={basketItem} />)

  const total = basketItems.reduce((total, curr) => {
    return total += curr.price * curr.quantity
  }, shipping.price)

  return (
    <>
      <ul>
        {listItems}
        <li key="shipping" className={BasketListStyles.shippingListItem}>
          <span>SHIPPING</span>
          <div className="flexbox-space-capture"></div>
          {hasMounted && shipping.price === 0 ? "FREE" : `€ ${shipping.price}`}
        </li>
      </ul>
      <hr />
      <div className={BasketListStyles.totalContainer}>
        <span>TOTAL</span>
        <span>{`€ ${total}`}</span>
      </div>
    </>
  )
}

export default BasketList