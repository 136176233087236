import React from "react"

import Layout from "../components/layout/layout"
import BasketList from "../components/layout/basketList"
import BasketDeliveryInfoBanner from "../components/shop/basketDeliveryInfoBanner"
import BasketAddressForm from "../components/shop/basketAddressForm"

import * as BasketPageStyles from "./../styles/basket.module.css"
import * as PageTitleStyles from "../components/pageTitle.module.css"
import Seo from "../components/seo"

const BasketPage = () => (
  <Layout>
    <Seo noindex title="Shopping basket" />
    <h1 className={PageTitleStyles.title}>Your shopping basket</h1>
    <div className={BasketPageStyles.basketContainer}>
      <BasketList />
      <BasketDeliveryInfoBanner />
      <BasketAddressForm />
    </div>
  </Layout>
)

export default BasketPage
